/**
 * Grid Variables - CH
 */
/**
 * Grid Variables - CH
 */
/**
 * Custom SASS Mixins - CH
 */
.select2-container {
  box-sizing: border-box;
  display: inline-block;
  margin: 0;
  position: relative;
  vertical-align: middle;
}

.select2-container .select2-selection--single {
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  height: 28px;
  user-select: none;
  -webkit-user-select: none;
}

.select2-container .select2-selection--single .select2-selection__rendered {
  display: block;
  padding-left: 8px;
  padding-right: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.select2-container .select2-selection--single .select2-selection__clear {
  position: relative;
}

.select2-container[dir="rtl"] .select2-selection--single .select2-selection__rendered {
  padding-right: 8px;
  padding-left: 20px;
}

.select2-container .select2-selection--multiple {
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  min-height: 32px;
  user-select: none;
  -webkit-user-select: none;
}

.select2-container .select2-selection--multiple .select2-selection__rendered {
  display: inline-block;
  overflow: hidden;
  padding-left: 8px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.select2-container .select2-search--inline {
  float: left;
}

.select2-container .select2-search--inline .select2-search__field {
  box-sizing: border-box;
  border: none;
  font-size: 100%;
  margin-top: 5px;
  padding: 0;
}

.select2-container .select2-search--inline .select2-search__field::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

.select2-dropdown {
  box-sizing: border-box;
  display: block;
  position: absolute;
  left: -100000px;
  width: 100%;
  z-index: 1051;
  min-width: 10em;
  font-size: 2em;
  padding: 1rem 2rem 2rem;
  border: 1px solid #dbdbdb;
  border-radius: 1px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
  background: white;
  margin-top: 1em;
}

.select2-dropdown:before {
  display: block;
  position: absolute;
  top: -11px;
  left: 15px;
  width: 20px;
  height: 20px;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  border: 1px solid #dbdbdb;
  border-right: 0;
  border-bottom: 0;
  background: #fff;
  content: "";
}

.select2-results {
  display: block;
}

.select2-results__options {
  list-style: none;
  margin: 0;
  padding: 0;
}

.select2-results__option {
  padding: 6px 0;
  user-select: none;
  -webkit-user-select: none;
}

.select2-results__option[aria-selected] {
  cursor: pointer;
}

.select2-container--open .select2-dropdown {
  left: 0;
}

.select2-container--open .select2-dropdown--above {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.select2-container--open .select2-dropdown--below {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.select2-search--dropdown {
  display: block;
  padding: 4px;
}

.select2-search--dropdown .select2-search__field {
  padding: 4px;
  width: 100%;
  box-sizing: border-box;
}

.select2-search--dropdown .select2-search__field::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

.select2-search--dropdown.select2-search--hide {
  display: none;
}

.select2-close-mask {
  border: 0;
  margin: 0;
  padding: 0;
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  min-height: 100%;
  min-width: 100%;
  height: auto;
  width: auto;
  opacity: 0;
  z-index: 99;
  background-color: #fff;
  filter: alpha(opacity=0);
}

.select2-hidden-accessible {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
  line-height: 28px;
}

.select2-container--default .select2-selection--single .select2-selection__clear {
  cursor: pointer;
  float: right;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 26px;
  position: absolute;
  top: 1px;
  right: -8px;
  width: 20px;
}

.select2-container--default .select2-selection--single .select2-selection__arrow b {
  background-image: url(../images/icons/icon-arrow--black.svg);
  background-repeat: no-repeat;
  background-position: 100% 50%;
  height: 0.8em;
  left: 50%;
  margin-left: -4px;
  margin-top: -2px;
  position: absolute;
  top: 50%;
  width: 1.4em;
}

.select2-container--default[dir="rtl"] .select2-selection--single .select2-selection__clear {
  float: left;
}

.select2-container--default[dir="rtl"] .select2-selection--single .select2-selection__arrow {
  left: 1px;
  right: auto;
}

.select2-container--default.select2-container--disabled .select2-selection--single {
  background-color: #eee;
  cursor: default;
}

.select2-container--default.select2-container--disabled .select2-selection--single .select2-selection__clear {
  display: none;
}

.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent #888 transparent;
  border-width: 0 4px 5px 4px;
}

.select2-container--default .select2-selection--multiple {
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: text;
}

.select2-container--default .select2-selection--multiple .select2-selection__rendered {
  box-sizing: border-box;
  list-style: none;
  margin: 0;
  padding: 0 5px;
  width: 100%;
}

.select2-container--default .select2-selection--multiple .select2-selection__rendered li {
  list-style: none;
}

.select2-container--default .select2-selection--multiple .select2-selection__placeholder {
  color: #999;
  margin-top: 5px;
  float: left;
}

.select2-container--default .select2-selection--multiple .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold;
  margin-top: 5px;
  margin-right: 10px;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice {
  background-color: #e4e4e4;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: default;
  float: left;
  margin-right: 5px;
  margin-top: 5px;
  padding: 0 5px;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
  color: #999;
  cursor: pointer;
  display: inline-block;
  font-weight: bold;
  margin-right: 2px;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove:hover {
  color: #333;
}

.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice,
.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__placeholder,
.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-search--inline {
  float: right;
}

.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice {
  margin-left: 5px;
  margin-right: auto;
}

.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice__remove {
  margin-left: 2px;
  margin-right: auto;
}

.select2-container--default.select2-container--focus .select2-selection--multiple {
  border: solid black 1px;
  outline: 0;
}

.select2-container--default.select2-container--disabled .select2-selection--multiple {
  background-color: #eee;
  cursor: default;
}

.select2-container--default.select2-container--disabled .select2-selection__choice__remove {
  display: none;
}

.select2-container--default.select2-container--open.select2-container--above .select2-selection--single,
.select2-container--default.select2-container--open.select2-container--above .select2-selection--multiple {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.select2-container--default.select2-container--open.select2-container--below .select2-selection--single,
.select2-container--default.select2-container--open.select2-container--below .select2-selection--multiple {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.select2-container--default .select2-search--dropdown .select2-search__field {
  border: 1px solid #aaa;
}

.select2-container--default .select2-search--inline .select2-search__field {
  background: transparent;
  border: none;
  outline: 0;
  box-shadow: none;
  -webkit-appearance: textfield;
}

.select2-container--default .select2-results > .select2-results__options {
  max-height: 200px;
  overflow-y: auto;
}

.select2-container--default .select2-results__option[role=group] {
  padding: 0;
}

.select2-container--default .select2-results__option[aria-disabled=true] {
  color: #999;
}

.select2-container--default .select2-results__option .select2-results__option {
  padding-left: 1em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__group {
  padding-left: 0;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -1em;
  padding-left: 2em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -2em;
  padding-left: 3em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -3em;
  padding-left: 4em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -4em;
  padding-left: 5em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -5em;
  padding-left: 6em;
}

.select2-container--default .select2-results__group {
  cursor: default;
  display: block;
  padding: 6px;
}

.select2-container--classic .select2-selection--single {
  background-color: #f7f7f7;
  border: 1px solid #aaa;
  border-radius: 4px;
  outline: 0;
  background-image: -webkit-linear-gradient(top, white 50%, #eeeeee 100%);
  background-image: -o-linear-gradient(top, white 50%, #eeeeee 100%);
  background-image: linear-gradient(to bottom, white 50%, #eeeeee 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFFFFFFF', endColorstr='#FFEEEEEE', GradientType=0);
}

.select2-container--classic .select2-selection--single:focus {
  border: 1px solid #5897fb;
}

.select2-container--classic .select2-selection--single .select2-selection__rendered {
  color: #444;
  line-height: 28px;
}

.select2-container--classic .select2-selection--single .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold;
  margin-right: 10px;
}

.select2-container--classic .select2-selection--single .select2-selection__placeholder {
  color: #999;
}

.select2-container--classic .select2-selection--single .select2-selection__arrow {
  background-color: #ddd;
  border: none;
  border-left: 1px solid #aaa;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  height: 26px;
  position: absolute;
  top: 1px;
  right: 1px;
  width: 20px;
  background-image: -webkit-linear-gradient(top, #eeeeee 50%, #cccccc 100%);
  background-image: -o-linear-gradient(top, #eeeeee 50%, #cccccc 100%);
  background-image: linear-gradient(to bottom, #eeeeee 50%, #cccccc 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFEEEEEE', endColorstr='#FFCCCCCC', GradientType=0);
}

.select2-container--classic .select2-selection--single .select2-selection__arrow b {
  border-color: #888 transparent transparent transparent;
  border-style: solid;
  border-width: 5px 4px 0 4px;
  height: 0;
  left: 50%;
  margin-left: -4px;
  margin-top: -2px;
  position: absolute;
  top: 50%;
  width: 0;
}

.select2-container--classic[dir="rtl"] .select2-selection--single .select2-selection__clear {
  float: left;
}

.select2-container--classic[dir="rtl"] .select2-selection--single .select2-selection__arrow {
  border: none;
  border-right: 1px solid #aaa;
  border-radius: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  left: 1px;
  right: auto;
}

.select2-container--classic.select2-container--open .select2-selection--single {
  border: 1px solid #5897fb;
}

.select2-container--classic.select2-container--open .select2-selection--single .select2-selection__arrow {
  background: transparent;
  border: none;
}

.select2-container--classic.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent #888 transparent;
  border-width: 0 4px 5px 4px;
}

.select2-container--classic.select2-container--open.select2-container--above .select2-selection--single {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  background-image: -webkit-linear-gradient(top, white 0%, #eeeeee 50%);
  background-image: -o-linear-gradient(top, white 0%, #eeeeee 50%);
  background-image: linear-gradient(to bottom, white 0%, #eeeeee 50%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFFFFFFF', endColorstr='#FFEEEEEE', GradientType=0);
}

.select2-container--classic.select2-container--open.select2-container--below .select2-selection--single {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  background-image: -webkit-linear-gradient(top, #eeeeee 50%, white 100%);
  background-image: -o-linear-gradient(top, #eeeeee 50%, white 100%);
  background-image: linear-gradient(to bottom, #eeeeee 50%, white 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFEEEEEE', endColorstr='#FFFFFFFF', GradientType=0);
}

.select2-container--classic .select2-selection--multiple {
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: text;
  outline: 0;
}

.select2-container--classic .select2-selection--multiple:focus {
  border: 1px solid #5897fb;
}

.select2-container--classic .select2-selection--multiple .select2-selection__rendered {
  list-style: none;
  margin: 0;
  padding: 0 5px;
}

.select2-container--classic .select2-selection--multiple .select2-selection__clear {
  display: none;
}

.select2-container--classic .select2-selection--multiple .select2-selection__choice {
  background-color: #e4e4e4;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: default;
  float: left;
  margin-right: 5px;
  margin-top: 5px;
  padding: 0 5px;
}

.select2-container--classic .select2-selection--multiple .select2-selection__choice__remove {
  color: #888;
  cursor: pointer;
  display: inline-block;
  font-weight: bold;
  margin-right: 2px;
}

.select2-container--classic .select2-selection--multiple .select2-selection__choice__remove:hover {
  color: #555;
}

.select2-container--classic[dir="rtl"] .select2-selection--multiple .select2-selection__choice {
  float: right;
  margin-left: 5px;
  margin-right: auto;
}

.select2-container--classic[dir="rtl"] .select2-selection--multiple .select2-selection__choice__remove {
  margin-left: 2px;
  margin-right: auto;
}

.select2-container--classic.select2-container--open .select2-selection--multiple {
  border: 1px solid #5897fb;
}

.select2-container--classic.select2-container--open.select2-container--above .select2-selection--multiple {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.select2-container--classic.select2-container--open.select2-container--below .select2-selection--multiple {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.select2-container--classic .select2-search--dropdown .select2-search__field {
  border: 1px solid #aaa;
  outline: 0;
}

.select2-container--classic .select2-search--inline .select2-search__field {
  outline: 0;
  box-shadow: none;
}

.select2-container--classic .select2-dropdown {
  background-color: white;
  border: 1px solid transparent;
}

.select2-container--classic .select2-dropdown--above {
  border-bottom: none;
}

.select2-container--classic .select2-dropdown--below {
  border-top: none;
}

.select2-container--classic .select2-results > .select2-results__options {
  max-height: 200px;
  overflow-y: auto;
}

.select2-container--classic .select2-results__option[role=group] {
  padding: 0;
}

.select2-container--classic .select2-results__option[aria-disabled=true] {
  color: grey;
}

.select2-container--classic .select2-results__option--highlighted[aria-selected] {
  background-color: #3875d7;
  color: white;
}

.select2-container--classic .select2-results__group {
  cursor: default;
  display: block;
  padding: 6px;
}

.select2-container--classic.select2-container--open .select2-dropdown {
  border-color: #5897fb;
}

ul.search-result-list {
  display: flex;
  flex-wrap: wrap;
}

ul.search-result-list li,
ul.search-result-list li > div,
ul.search-result-list article > a,
ul.search-result-list .event-wrapper {
  display: flex;
  flex-basis: 100%;
}

ul.search-result-list li:not(:first-child) .event-wrapper {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
}

ul.search-result-list li:first-of-type {
  flex-basis: 100%;
}

@media (min-width: 992px) {
  ul.search-result-list li:first-of-type article .event-wrapper {
    flex-direction: row-reverse;
  }
}

ul.search-result-list li:first-of-type article .event-wrapper p {
  display: none;
}

ul.search-result-list li:first-of-type article .event-wrapper p:first-of-type {
  display: block;
}

ul.search-result-list li:first-of-type article h2 {
  margin: 0.7em 0;
}

ul.search-result-list li {
  flex-basis: 33%;
  flex-grow: 1;
  min-width: 30em;
}

@media (max-width: 767px) {
  ul.search-result-list li {
    flex-basis: 100%;
    min-width: auto;
  }
}

ul.search-result-list article {
  width: 100%;
  display: flex;
  flex-basis: 100%;
}

ul.search-result-list article .image-holder {
  border-left: 1px solid white;
  border-bottom: 1px solid white;
}

ul.search-result-list article .image-holder img {
  vertical-align: bottom;
  object-fit: cover;
  width: 100%;
}

ul.search-result-list article .hgroup {
  background: #eaebeb;
  flex-grow: 1;
  min-height: 27.6em;
  padding: 3.6em 4.9em 2em;
}

@media (max-width: 991px) {
  ul.search-result-list article .hgroup {
    min-height: auto;
  }
}

ul.search-result-list article .hgroup * {
  display: none;
}

ul.search-result-list article .hgroup .tags {
  font-size: 1.6em;
  color: #55565b;
  display: block;
}

ul.search-result-list article .hgroup .tags .type {
  display: inline;
  font-weight: 700;
  text-transform: capitalize;
}

ul.search-result-list article .hgroup .tags .type:after {
  content: '|';
  margin-left: 0.7em;
  margin-right: 0.8em;
  font-weight: 300;
  opacity: 0.2;
}

ul.search-result-list article .hgroup .tags time {
  font-weight: 300;
  display: inline;
}

ul.search-result-list article .hgroup h2 {
  font-size: 3.2em;
  font-family: "Frank Ruhl Libre", serif;
  margin: 0.7em 0;
  display: block;
}

@media (max-width: 991px) {
  ul.search-result-list article .hgroup h2 {
    font-size: 2.4em;
  }
}

ul.search-result-list article .hgroup h2 a {
  display: inline;
  color: black;
  font-size: 1em;
}

ul.search-result-list article .hgroup p {
  display: none;
  font-size: 2em;
}

@media (max-width: 767px) {
  ul.search-result-list article .hgroup p {
    font-size: 1.6em;
  }
}

ul.search-result-list article .hgroup p:nth-of-type(1) {
  display: block;
}

.news-index h1 {
  margin: 1em 0;
}

.news-index ul.search-result-list li:first-of-type article > .event-wrapper {
  flex-wrap: wrap;
}

.news-index ul.search-result-list li:first-of-type article > .event-wrapper .hgroup {
  flex-basis: 40%;
  padding-top: 5em;
}

@media (max-width: 767px) {
  .news-index ul.search-result-list li:first-of-type article > .event-wrapper .hgroup {
    position: relative;
    bottom: 0;
    min-height: auto;
    padding-top: 4em;
    padding-bottom: 4em;
  }
}

.news-index ul.search-result-list li:first-of-type article > .event-wrapper .image-holder {
  position: relative;
  flex-basis: 60%;
  overflow: hidden;
}

@media (min-width: 768px) {
  .news-index ul.search-result-list li:first-of-type article > .event-wrapper .image-holder img {
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    transform: translate(-50%, -50%);
  }
}

@media (max-width: 767px) {
  .news-index ul.search-result-list li:first-of-type article > .event-wrapper .image-holder {
    flex-basis: 100%;
  }
}

.facet-component.facet-dropdown {
  margin: -10px 0 7.5em;
}

@media (max-width: 767px) {
  .facet-component.facet-dropdown {
    margin: -24px 0 2.5em;
  }
}

.facet-component.facet-dropdown .component-content {
  display: flex;
  justify-content: center;
}

.facet-component.facet-dropdown .component-content select {
  -webkit-appearance: none;
  background: none;
  border: none;
  box-shadow: none;
  outline: 0;
  width: auto;
  min-width: 0px;
  padding: 0;
  height: auto;
  font-size: 2em;
  background-image: url(../images/icons/icon-arrow--black.svg);
  background-repeat: no-repeat;
  background-position: 100% 50%;
}

.facet-component.facet-dropdown .component-content .select2-container {
  width: auto !important;
}

.facet-component.facet-dropdown .component-content .select2-container .select2-selection__rendered {
  font-size: 2em;
  font-weight: 400;
  color: rgba(85, 86, 91, 0.8);
}

.facet-component.facet-dropdown .facet-heading {
  border-bottom: 0;
  padding: 0;
  margin: 0;
}

.facet-component.facet-dropdown .facet-heading h4 {
  padding: 0;
}

.facet-component.facet-dropdown .facet-title {
  font-size: 2em;
  font-weight: 400;
  color: #55565b;
  margin-right: 0.2em;
}

.facet-component.facet-dropdown .clear-filter {
  display: none !important;
}

.summary-mode ul.search-result-list {
  flex-direction: column;
  height: 90vh;
  justify-content: space-evenly;
  background: #8c7253;
  min-height: 1000px;
}

@media (max-width: 1199px) {
  .summary-mode ul.search-result-list {
    flex-wrap: nowrap;
    height: auto;
    min-height: 0;
    padding-bottom: 3.6em;
  }
}

.summary-mode ul.search-result-list li {
  width: 50%;
  flex-basis: auto;
  flex-grow: 1;
}

@media (max-width: 1199px) {
  .summary-mode ul.search-result-list li {
    width: 100%;
  }
}

.summary-mode ul.search-result-list li article {
  position: relative;
}

.summary-mode ul.search-result-list li .image-holder {
  display: none;
  border: 0;
}

.summary-mode ul.search-result-list li .hgroup {
  background: none;
  min-height: auto;
  padding: 3.6em 7.7em 0;
  overflow: hidden;
}

.summary-mode ul.search-result-list li .hgroup h2 a {
  color: white;
}

@media (max-width: 767px) {
  .summary-mode ul.search-result-list li .hgroup {
    padding-bottom: 4em;
    min-height: auto;
    position: relative;
    bottom: 0;
  }
}

.summary-mode ul.search-result-list li .hgroup .btn.btn-link {
  font-size: 1.6em;
  color: white;
  margin-top: 0.6em;
  display: inline-flex;
}

.summary-mode ul.search-result-list li .hgroup .btn.btn-link:after {
  background-color: white;
}

@media (max-width: 1199px) {
  .summary-mode ul.search-result-list li .hgroup {
    padding: 3.6em 2.3em 2em;
  }
}

.summary-mode ul.search-result-list li .hgroup h2,
.summary-mode ul.search-result-list li .hgroup .tags,
.summary-mode ul.search-result-list li .hgroup p {
  color: white;
}

.summary-mode ul.search-result-list li .hgroup p, .summary-mode ul.search-result-list li .hgroup ul, .summary-mode ul.search-result-list li .hgroup blockquote, .summary-mode ul.search-result-list li .hgroup cite {
  display: none;
}

.summary-mode ul.search-result-list li .hgroup p:first-of-type {
  display: block;
}

@media (max-width: 1199px) {
  .summary-mode ul.search-result-list li:first-of-type .hgroup {
    left: 0;
  }
}

@media (max-width: 767px) {
  .summary-mode ul.search-result-list li:first-of-type .image-holder:before {
    background: #000;
  }
}

@media (max-width: 767px) {
  .summary-mode ul.search-result-list li:first-of-type {
    height: auto !important;
  }
  .summary-mode ul.search-result-list li:first-of-type .hgroup {
    position: relative;
    bottom: auto;
  }
}

@media (min-width: 1200px) {
  .summary-mode ul.search-result-list li:first-of-type {
    flex-basis: 100%;
    height: 100vw;
  }
  .summary-mode ul.search-result-list li:first-of-type .image-holder {
    display: block;
    position: absolute;
    z-index: 0;
    width: 100%;
    height: 100%;
    left: 0;
  }
  .no-object-fit .summary-mode ul.search-result-list li:first-of-type .image-holder {
    overflow: hidden;
  }
  .summary-mode ul.search-result-list li:first-of-type .image-holder:before {
    content: '';
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 30%, #000000 100%);
    display: block;
    width: 100%;
    left: 0;
    right: 0;
    height: 100%;
    position: absolute;
    opacity: 0.6;
    z-index: 1;
  }
  .summary-mode ul.search-result-list li:first-of-type .image-holder img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .no-object-fit .summary-mode ul.search-result-list li:first-of-type .image-holder img {
    width: auto;
    max-width: 10000%;
    position: absolute;
    top: 0;
  }
  .summary-mode ul.search-result-list li:first-of-type .hgroup {
    position: absolute;
    z-index: 1;
    bottom: 4.5em;
    left: 1.8em;
  }
  .summary-mode ul.search-result-list li:first-of-type .hgroup h2 {
    font-size: 5.2em;
    margin-top: 0em;
  }
}

@media (max-width: 1199px) {
  .summary-mode ul.search-result-list li:nth-of-type(2) {
    margin-top: 4.4em;
  }
}

@media (min-width: 1200px) {
  .summary-mode ul.search-result-list li:nth-of-type(2) {
    margin-top: 12.5vh;
  }
  .summary-mode ul.search-result-list li:last-of-type {
    margin-bottom: 6.9em;
  }
}

@media (max-width: 767px) {
  .select2-container--open .select2-dropdown {
    left: 50% !important;
    transform: translateX(-50%);
  }
  .select2-container--open .select2-dropdown:before {
    left: 50%;
    transform: translateX(-50%) rotate(45deg);
  }
  .select2-container--open .select2-dropdown .select2-results__option {
    text-align: center;
  }
}

.field-eventdate {
  display: block;
  margin-top: 1em;
  margin-bottom: 1em;
}
